<script setup lang="ts">
const core = useCoreStore();
const search = useSearchStore();
const show = ref(false);

const props = defineProps({
  forCategory: Boolean,
})

</script>

<template>
  <div class="w-full rounded-d md:h-20 flex md:flex-row flex-col justify-between text-xl bg-gray select-none relative">
    <LazyPickersDatePicker
        v-model="show"
        v-if="core.appLoaded"
    />
    <div
        :class="{'pl-10 md:pl-20 lg:pl-32 lg:w-[70%] justify-between text-text-default': !forCategory, 'pl-10 justify-around bg-secondary text-white px-2 rounded-d' : forCategory}"
        class=" w-full flex relative cursor-pointer"
        @click="show = true"
    >
      <div class="w-8 h-8 absolute left-3 flex inset-y-center">
        <Icon
            name="fluent:calendar-32-regular"
            class="w-8 h-8"
        />
      </div>
      <div class="flex flex-col justify-center p-6 md:p-0"
           :class="{'sm:text-sm text-xs': forCategory, 'md:text-xl text-sm': !forCategory}">
            <span
                class=" font-bold uppercase"
            >
              {{ search.check_in ? $format(search.check_in) : $t('booking.check in') }}
            </span>
        <span class="date_picker_item_text">
              {{ search.check_in ? $format(search.check_in, 'EEEEEEE') : $t('booking.not set') }}
            </span>
      </div>
      <div class="flex-grow md:h-full hidden sm:flex flex-center px-6" v-if="!forCategory">
        <div class="w-full h-[2px] bg-text-default relative md:hidden xl:block">
          <div class="w-3 h-3 rounded-full bg-text-default absolute inset-y-center left-0"></div>
          <div class="w-[2px] h-3 rounded-full bg-text-default absolute inset-y-center right-0"></div>
        </div>
      </div>
      <div class="flex flex-col justify-center p-6 md:p-0"
           :class="{'md:text-sm text-xs': forCategory, 'md:text-xl text-sm': !forCategory}" @click="show = true">
            <span class="font-bold uppercase">
              {{ search.check_out ? $format(search.check_out) : $t('booking.check out') }}
            </span>
        <span class="date_picker_item_text">
              {{ search.check_out ? $format(search.check_out, 'EEEEEEE') : $t('booking.not set') }}
            </span>
      </div>
    </div>
    <slot name="button"></slot>
  </div>
</template>

<style scoped>

</style>